import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  FC,
  useCallback,
} from "react";
import {
  getTokens,
  removeTokens,
  handleError,
  sortData,
} from "../shared/utils";
import { useHistory } from "react-router-dom";
import { useSubscription } from "@cobuildlab/react-simple-state";
import { fetchMeAction, fetchMeBasicAction } from "../api/me/me-actions";
import { OnFetchMe, OnFetchMeError } from "../api/me/me-events";
import { User, Notification } from "@devsplan/wealthclub-shared";
import {
  AUTH_ROUTE,
  DEVICE_SESSION_ID,
  INDEX_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_PAGE_URL,
  NOTIFICATIONS_ROUTE,
} from "../shared/constants";
import { notification, Button } from "antd";
import { Columns } from "../components";
import {
  readMassiveNotificationAction,
  updateNotificationAction,
} from "../modules/notifications/notifications-actions";
import {
  OnReadMassiveNotification,
  OnUpdateNotification,
} from "../modules/notifications/notifications-events";
import { logoutMe } from "../api/me/me";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { deleteDeviceSession } from "../shared/services";

const AuthContext: any = createContext(null);

export const Provider: FC = ({ children }) => {
  const [state, setState] = useState<{
    tokenError?: boolean;
    user: User | null;
  }>({
    tokenError: false,
    user: null,
  });
  const { user } = state;
  const history = useHistory();
  const { t } = useTranslation();
  const tokens = getTokens();
  const { token } = useMemo(() => {
    return tokens;
  }, [tokens]);

  const goToLogin = useCallback(() => {
    window.location.href = LOGOUT_PAGE_URL;
  }, []);

  const goToNotifications = useCallback(() => {
    notification.close("0");
    history.push(NOTIFICATIONS_ROUTE);
  }, [history]);

  const getSession = () => {
    if (token) {
      fetchMeAction();
      fetchMeBasicAction();
    }
  };
  const logout = useCallback(() => {
    logoutMe().then(() => {
      removeTokens();
      setState({ user: null });
      goToLogin();
      OnFetchMe.dispatch(null);

      const deviceSessionId = localStorage.getItem(DEVICE_SESSION_ID);

      if (deleteDeviceSession) {
        deleteDeviceSession(parseInt(deviceSessionId || "0"));
      }
    });
  }, [goToLogin]);

  const handleOnClickRead = (userNotification: Notification) => {
    notification.close(userNotification.id.toString());

    if (userNotification.important) {
      readMassiveNotificationAction(userNotification.id);
    } else {
      updateNotificationAction(userNotification.id, 1);
    }
  };

  useSubscription(OnUpdateNotification, () => {
    fetchMeAction();
  });

  useSubscription(OnReadMassiveNotification, () => {
    fetchMeAction();
  });

  useSubscription(
    OnFetchMe,
    (user) => {
      setState({ user });

      if (LOGIN_ROUTE === window.location.pathname) {
        history.push(INDEX_ROUTE);
      }

      if (OnUpdateNotification.get() === null && user) {
        if (user?.notifications.length >= 4) {
          const data = {
            message: (
              <>
                <p
                  style={{
                    margin: 0,
                    fontSize: 16,
                  }}
                >
                  {t("You have")} ({user?.notifications.length}){" "}
                  {t("unread notifications")}
                </p>
              </>
            ),
            key: "0",
            description: (
              <Columns justify="start" align="top">
                <span>{t("Click on the button to see all notifications")}</span>
                <Button
                  type="link"
                  style={{ marginLeft: -14 }}
                  onClick={() => goToNotifications()}
                >
                  {t("See notifications")}
                </Button>
              </Columns>
            ),
            duration: 12,
          };

          const placement = "bottomRight";
          notification.success({ ...data, placement });
        } else {
          sortData(user?.notifications).forEach(
            (userNotification: Notification) => {
              const data = {
                message: (
                  <>
                    <p
                      style={{
                        margin: 0,
                        fontSize: 16,
                      }}
                    >
                      {userNotification.title}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        fontSize: 12,
                        color: "rgba(255, 255, 255, 0.5)",
                      }}
                    >
                      {moment(userNotification.created_at).fromNow()}
                    </p>
                  </>
                ),
                key: userNotification.id.toString(),
                description: (
                  <Columns justify="start" align="top">
                    <span>{userNotification.description}</span>
                    <Button
                      type="link"
                      style={{ marginLeft: -14 }}
                      onClick={() => handleOnClickRead(userNotification)}
                    >
                      {t("Mark as read")}
                    </Button>
                  </Columns>
                ),
                duration: userNotification.important ? 12 : 6,
              };

              const placement = "bottomRight";

              if (userNotification.type === "success") {
                notification.success({ ...data, placement });
              }

              if (userNotification.type === "info") {
                notification.info({ ...data, placement });
              }

              if (userNotification.type === "warning") {
                notification.warning({ ...data, placement });
              }

              if (userNotification.type === "error") {
                notification.error({ ...data, placement });
              }
            }
          );
        }
      }
    },
    [OnUpdateNotification]
  );

  useSubscription(
    OnFetchMeError,
    async (error: any) => {
      if (!window.location.pathname.includes(AUTH_ROUTE)) {
        removeTokens();
        setState({ user: null });
        goToLogin();
        handleError(error);
      } else {
        removeTokens();
        setState({ user: null });
      }
    },
    []
  );

  useEffect(() => {
    getSession();
    // eslint-disable-next-line
  }, []);

  const value = useMemo(() => {
    return { user, logout };
    // eslint-disable-next-line
  }, [user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): { user: User; logout: Function } => {
  const { user, logout } = useContext(AuthContext);

  return { user, logout };
};
