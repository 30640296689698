import { handleResponse } from "./../../shared/utils";
import {
  fetchMe,
  fetchMeBasic,
  setActiveCrypto,
  setActiveCryptoSystem,
  setActiveSystem,
} from "./me";
import {
  OnFetchMeError,
  OnFetchMe,
  OnSetActiveSystem,
  OnSetActiveSystemError,
  OnSetActiveCrypto,
  OnSetActiveCryptoError,
  OnSetActiveCryptoSystem,
  OnSetActiveCryptoSystemError,
  OnFetchMeBasicError,
  OnFetchMeBasic,
} from "./me-events";

export const fetchMeAction = async () => {
  let response;

  try {
    response = await fetchMe();
  } catch (error) {
    OnFetchMeError.dispatch(error);
  }

  handleResponse(OnFetchMe, OnFetchMeError, response);
};

export const fetchMeBasicAction = async () => {
  let response;

  try {
    response = await fetchMeBasic();
  } catch (error) {
    OnFetchMeBasicError.dispatch(error);
  }

  handleResponse(OnFetchMeBasic, OnFetchMeBasicError, response);
};

export const setActiveSystemAction = async (system: string) => {
  let response;

  try {
    response = await setActiveSystem(system);
  } catch (error) {
    OnSetActiveSystemError.dispatch(error);
  }

  handleResponse(OnSetActiveSystem, OnSetActiveSystemError, response);
};

export const setActiveCryptoAction = async (system: string) => {
  let response;

  try {
    response = await setActiveCrypto(system);
  } catch (error) {
    OnSetActiveCryptoError.dispatch(error);
  }

  handleResponse(OnSetActiveCrypto, OnSetActiveCryptoError, response);
};

export const setActiveCryptoSystemAction = async (system: string) => {
  let response;

  try {
    response = await setActiveCryptoSystem(system);
  } catch (error) {
    OnSetActiveCryptoSystemError.dispatch(error);
  }

  handleResponse(
    OnSetActiveCryptoSystem,
    OnSetActiveCryptoSystemError,
    response
  );
};
