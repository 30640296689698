import {
  Button,
  Card,
  Input,
  Modal,
  Row,
  Tabs,
  Select,
  Upload,
  Menu,
  notification,
} from "antd";
import Layout from "antd/lib/layout/layout";
import styled from "styled-components";
import { verifyEmailSentWithParam } from "../modules/login/login-services";
import { BLUE_COLOR, PRIMARY_COLOR } from "../shared/constants";

export const ButtonBlack = styled.div`
  line-height: 70px;
  height: 80px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  width: 100%;
  cursor: pointer;
  word-break: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;
  transition: all 0.3s;
  position: relative;
  text-transform: uppercase;
  border-radius: 20px;
  margin-bottom: 10px;

  &:hover {
    color: rgb(240, 203, 101);
  }

  span {
    transition: all 0.3s;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 2px;
    border-bottom-width: 2px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: rgb(240, 203, 101);
    border-bottom-color: rgb(240, 203, 101);
    transform: scale(0.1, 1);
  }

  &:hover span {
    letter-spacing: 2px;
  }

  &:hover::before {
    opacity: 1;
    transform: scale(1, 1);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &:hover::after {
    opacity: 0;
    transform: scale(0.1, 1);
  }
`;

export const Columns = styled(Row)`
  flex-direction: column;
`;

export const CustomCard = styled(Card)`
  width: 100%;
  border: 2px solid ${PRIMARY_COLOR};
  background-color: rgba(0, 0, 0, 0.3) !important;
  color: white;
  -webkit-box-shadow: 3px 2px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 2px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 2px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  position: relative;

  .ant-card-head {
    border-bottom: 1px solid ${PRIMARY_COLOR} !important;
  }
`;

export const CustomInput = styled(Input)`
  width: 100%;
  border: 1px solid ${PRIMARY_COLOR};
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
`;

export const CustomTextArea = styled(Input.TextArea)`
  width: 100%;
  border: 1px solid ${PRIMARY_COLOR};
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
`;

export const CustomInputPassword = styled(Input.Password)`
  width: 100%;
  border: 1px solid ${PRIMARY_COLOR};
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
`;

export const CustomButton = styled(Button)`
  color: ${BLUE_COLOR};
  border-radius: 16px;
`;

export const CustomTabs = styled(Tabs)`
  position: relative;
  overflow: visible;

  .ant-tabs-nav {
    margin: 0 !important;
  }

  .ant-tabs-tab {
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .ant-tabs-tab-active {
    background-color: rgba(0, 0, 0, 0.3) !important;
    border-color: ${PRIMARY_COLOR} !important;
  }
`;

export const CustomModalLegacy = styled(Modal)<{
  withoutBg?: boolean;
  isGray?: boolean;
}>`
  .ant-modal-content {
    background-image: url(/images/background-grey.png);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    
    border: 1px solid ${PRIMARY_COLOR};
  }

`;

export const CustomModal = styled(Modal)<{
  withoutBg?: boolean;
  isGray?: boolean;
}>`
  .ant-modal-content {
    background-image: ${(props) =>
      !props.withoutBg
        ? !props.isGray
          ? `url(/images/background.png)`
          : `url(/images/background-grey.png)`
        : ""};
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 4px;
    border: 1px solid ${PRIMARY_COLOR};
  }

  .ant-modal-header {
    background-color: transparent !important;
    border-bottom-color: white;
  }

  .ant-modal-footer {
    border-top-color: white;
  }
`;

export const TextJustify = styled.p`
  text-align: justify;
`;

export const CustomSelect = styled(Select)`
  width: 100%;
  border: 1px solid ${PRIMARY_COLOR};
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
`;

export const CustomMenu = styled(Menu)`
  top: -20px;
`;

export const CustomUploadDragger = styled(Upload.Dragger)`
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 1px dashed ${PRIMARY_COLOR} !important;
  margin-bottom: 16px;
`;
export const VideoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  video {
    cursor: pointer;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
    object-fit: cover;
  }
`;

export const TextEllipsis = styled.p<{ lines: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.lines}; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin: 0px;
`;

export const ScrollBar = styled.div`
  &::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 0px solid rgba(0, 0, 0, 0.3); /* creates padding around scroll thumb */
  }
`;

export const LayoutWithScrollBar = styled(Layout)`
  position: relative;

  &::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 0px solid rgba(0, 0, 0, 0.3); /* creates padding around scroll thumb */
  }
`;

export const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
`;

export const NotificationVerifyEmail = (
  message: string,
  btnText: string,
  successText: string,
  errorText: string,
  email: string
) => {
  return (
    <div>
      <p>{message}</p>
      <Button
        type="link"
        style={{ padding: 0 }}
        onClick={() => {
          verifyEmailSentWithParam(email).then((res) => {
            if (res.status === 200) {
              notification.success({
                message: successText,
                duration: 10,
              });
            } else {
              notification.error({
                message: errorText,
                duration: 5,
              });
            }
          });
        }}
      >
        {btnText}
      </Button>
    </div>
  );
};
