import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as AuthProvider } from "./providers/AuthProvider";
import { MainLoaderWithTimeout } from "./components/MainLoader";

import "swiper/swiper.min.css";
import "swiper/components/effect-flip/effect-flip.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import "./i18n/i18n";
import "./index.less";
import { DELAY_LAZY } from "./shared/constants";

const Page = React.lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(
        () => resolve(import(`./pages/${process.env.REACT_APP_PAGE}`)),
        DELAY_LAZY
      );
    })
);

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <AuthProvider>
        <Suspense
          fallback={<MainLoaderWithTimeout dark delay={DELAY_LAZY * 10} />}
        >
          <Page />
        </Suspense>
      </AuthProvider>
    </React.StrictMode>
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
