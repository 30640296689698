import { createEvent } from "@cobuildlab/react-simple-state";
import { Notification } from "@devsplan/wealthclub-shared";

export const OnUpdateNotification = createEvent();
export const OnUpdateNotificationError = createEvent();

export const OnFetchNotification = createEvent<Notification[]>();
export const OnFetchNotificationError = createEvent();

export const OnFetchReadAllNotification = createEvent<Notification[]>();
export const OnFetchReadAllNotificationError = createEvent();

export const OnReadMassiveNotification = createEvent();
export const OnReadMassiveNotificationError = createEvent();
