import { API_URL_NODE, WCC_API_URL } from "./constants";

export const BITCOIN_ADDRESS_ENDPOINT = API_URL_NODE + "/bitcoin/address";

export const BITCOIN_API_INFO = API_URL_NODE + "/bitcoin/info";

export const PAYMENTS_ENDPOINT = API_URL_NODE + "/payments";

export const DEVICE_SESSIONS_ENDPOINT = API_URL_NODE + "/sessions";

// export const BITCOIN_BALANCE_ENDPOINT = API_URL_NODE + "/bitcoin/balance";

export const VALIDATIONS_EMAILS_ENDPOINT = "/validations/emails";

export const AMBASSADORS_ENDPOINT = "/ambassadors";

export const COIN_PAYMENTS_ENDPOINT = "/coin_payments";

export const WCC_RESET_PASSWORD_ENDPOINT = WCC_API_URL + "/user/password";

export const FEEDBACKS_ENDPOINT = "/feedbacks";
